<template>
  <div class="grid mb-8">
    <ContentCard
      class="grid-item"
      :class="{disabled: !app.url}"
      v-for="app in apps"
      v-bind:key="app.id"
    >
      <a
        :href="app.url ? app.url : false"
        :title="`Open ${app.name ? app.name : app.id}`"
        :class="{'hover-effect': !!app.url}"
        class="no-underline block text-sm font-semibold p-4 flex items-center justify-center h-full"
        target="_blank"
      >
        <div v-if="app.icon.img" :class="app.icon.class" class="flex flex-col items-center justify-center">
          <img :src="app.img" class="block max-h-full max-w-full"/>
        </div>
        <div v-else :class="app.icon.class" class="flex flex-col items-center justify-center">
          <i class="material-icons micon text-xl app-icon">{{app.icon.icon}}</i>
          <span>{{app.name}}</span>
        </div>
      </a>
    </ContentCard>
  </div>
</template>

<script>
import ContentCard from '../../../Common/ContentCard'
export default {
  name: 'ApplicationGrid',
  components: {
    ContentCard,
  },
  props: {
    applications: {
      type: Array,
      default: () => [],
      validator(items) {
        for (let item of items) {
          if (typeof item.id !== 'string') {
            return false
          }
          if (typeof item.name !== 'string') {
            return false
          }
          if (typeof item.url !== 'string') {
            return false
          }
        }
        return true
      },
    },
    errorMessage: String,
  },
  computed: {
    apps() {
      return this.applications.map(a => {
        const app = Object.assign({}, a)
        // If no name is defined, use the ID as the name
        if (!app.name) {
          app.name = app.id
        }
        // Return early if an icon is already defined for this application
        // This is turned off until we can whitelist this to an S3 bucket we own
        // if (app.icon) {
        //   return app
        // }
        // Specially supported popular apps to display nicer by default
        switch (app.name) {
          case 'Google':
            app.icon = {
              icon: 'mail_outline',
              class: 'text-google',
            }
            break
          case 'Salesforce':
            app.icon = {
              icon: 'filter_drama',
              class: 'text-salesforce',
            }
            break
          case 'Microsoft':
            app.icon = {
              icon: 'dashboard',
              class: 'text-microsoft',
            }
            break
          case 'Slack':
            app.icon = {
              icon: 'forum',
              class: 'text-slack'
            }
            break
          case 'Jira':
            app.icon = {
              icon: 'control_camera',
              class: 'text-jira'
            }
            break;
          default:
            app.icon = {
              icon: 'devices',
              class: '',
            }
        }
        return app
      })
    }
  }
}
</script>

<style scoped>
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1em;
  }
  .grid-item {
    margin-bottom: 0;
  }
  .hover-effect {
    transition: box-shadow 0.2s ease;
    box-shadow: 0;
  }
  .hover-effect:hover,
  .hover-effect:focus {
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.15);
  }
  .disabled {
    opacity: 0.6;
  }
  .disabled div {
    color: #CCCCCC;
  }
  .text-google {
    color: #D44638;
  }
  .text-microsoft {
    color: #EC5333
  }
  .text-salesforce {
    color: #199DD8;
  }
  .text-slack {
    color: #3F0E40;
  }
  .text-jira {
    color: #0052CC;
  }
</style>
