<template>
  <MainLayout>
    <Banner v-if="hasError" type="red" icon="error">
      {{ errorMessage }}
    </Banner>
    <Banner v-if="noApplications" type="blue" icon="info">
      No applications found. Contact your administrator to configure one.
    </Banner>
    <ApplicationGrid
      v-else-if="status === 'idle'"
      :applications="applications"
    />
    <div
      v-else-if="status === 'loading'"
      class="w-full flex items-center justify-center"
    >
      <ToznyLoader class="text-tozny w-24 h-24" />
    </div>
  </MainLayout>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

import MainLayout from '@/Common/MainLayout/MainLayout'
import ApplicationGrid from './Components/ApplicationGrid'
import ToznyLoader from '@/Common/ToznyLoader'
import Banner from '@/Common/Banner'

export default {
  name: 'Applications',
  components: {
    Banner,
    MainLayout,
    ApplicationGrid,
    ToznyLoader,
  },
  computed: {
    ...mapState('applications', ['status', 'applications', 'errorMessage']),
    ...mapGetters('applications', ['hasError', 'noApplications']),
  },
  methods: {
    ...mapActions('applications', [ 'initialize' ]),
  },
  created: async function() {
    await this.initialize()
  },
}
</script>

<style></style>
